import React, {useState} from 'react';
import {Box, Button, FormControl, Input, InputLabel, Link, Typography,} from '@mui/material';
import {Cards} from "../Pages/Cards";

const ImportForm = () => {
    const [importText, setImportText] = useState('');
    const [isImported, setIsImported] = useState(false);
    const [importedCards, setImportedCards] = useState({"cards": [], "errors": []});


    const handleImport = async () => {
        try {
            const response = await fetch('http://localhost:8080/api/v1/decks/import', {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body: importText,
            });

            // Handle the response as needed
            const result = await response.json();
            console.log(result);
            setImportedCards(result);
            // Assuming the import is successful, set isImported to true
            setIsImported(true);
        } catch (error) {
            console.error('Error during import:', error);
        }
    };

    const handleTextChange = (event) => {
        setImportText(event.target.value);
    };

    const cards = Cards(importedCards.cards, "")
    const errs = importedCards.errors

    return (
        <Box>

            {!isImported
                ?
                <>
                    <Typography variant="h5">Import Form</Typography>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="importText">Import Text</InputLabel>
                        <Input
                            id="importText"
                            multiline
                            rows={20}
                            value={importText}
                            onChange={handleTextChange}
                        />
                    </FormControl>
                    <Button variant="contained" color="primary" onClick={handleImport}>
                        Import
                    </Button>
                </>
                :
                <>
                    <Typography variant="h5">Import Result</Typography>
                    {errs.map(err =>
                        <p>
                            <Typography color={"red"}>Import Error: {err.message}</Typography>
                            <Typography>Type: {err.cardType}</Typography>
                            <Typography>Name: {err.cardName}</Typography>
                            <Typography>Set ID: {err.setID}</Typography>
                            <Typography>TCGO ID: {err.tcgoID}</Typography>
                            <Typography>Card #: {err.cardNumber}</Typography>
                            <Typography>Count: {err.count}</Typography>
                            {(err.setID !== null && err.setID !== "") &&
                                <Typography>
                                    <Link
                                        href={"/sets/" + err.setID + "/cards"}>
                                        Fix from Set
                                    </Link>
                                </Typography>
                            }
                            <Typography>
                                <Link
                                    href={"/cards/search?name=" + err.cardName + "&cardType=" + err.cardType}>
                                    Fix from Query
                                </Link>
                            </Typography>

                        </p>
                    )
                    }
                    {
                        cards
                    }

                </>
            }
        </Box>
    );
};

const ImportError = (err) => {
    return <>
        {err.message}
    </>
}

export default ImportForm;
