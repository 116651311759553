import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'sticky',
        top: 0,
        boxSizing: 'border-box',
    },
    subSppBar: {
        position: 'sticky',
        top: 100,
        boxSizing: 'border-box',
    },
    title: {
        flexGrow: 1,
    },
}));

function DisplayAlphaAlert() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleClose} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function StickyNavbar() {
    const classes = useStyles();

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <Stack spacing={1} direction="row">
                            <div>Pokédex-Ultra</div>
                            <Button variant="outlined">Alpha</Button>
                        </Stack>
                    </Typography>
                    <Stack spacing={1} direction="row">
                        <Button variant="contained" href={"/pokemon"}>Pokémon</Button>
                        <Button variant="contained" href={"/trainers"}>Trainers</Button>
                        <Button variant="contained" href={"/sets"}>Sets</Button>
                        <Button variant="contained" href={"/decks"}>Decks</Button>
                        <Button variant="contained" href={"/artists"}>Artists</Button>
                        <Button variant="contained" href={"/cards/search/form"}>Search</Button>
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default StickyNavbar;