import * as React from 'react';
import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

import {CardState, EnergyCard, PokemonCard, TrainerCard} from "../Components/PokemonCard";

export default function Card() {
    const [card, setCard] = useState(CardState);
    const {id} = useParams()
    console.log("ID" + {id})
    useEffect(() => {
        fetch(process.env.REACT_APP_POKEDEX_API_BASE_URL + "/api/v1/cards/" + id)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setCard(data);
            });
    }, CardState);

    var retval
    if (card.supertype === "Trainer") {
        retval = <TrainerCard id={id} card={card}/>
    } else if (card.supertype === "Energy") {
        retval = <EnergyCard id={id} card={card}/>
    } else {
        retval = <PokemonCard id={id} card={card}/>
    }
    return retval
}
