import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AspectRatio from '@mui/joy/AspectRatio';
import {Link} from "react-router-dom";
import {Box} from "@mui/material";
import {GetImageUrl} from "../Components/Utils";

const useStyles = makeStyles(() => ({
    card: {
        width: 300,
        height: 300,

        boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.3)",
        backgroundColor: "#fafafa",
    },
    media: {
        height: 80,
        width: "100%",
        objectFit: "cover",
    },
}));

function Sets() {
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_POKEDEX_API_BASE_URL + "/api/v1/sets")
            .then((response) => response.json())
            .then((data) => {
                setData(data);
            });
    }, []);
    return (
        <Box sx={{ marginLeft: '50px', marginRight: ''}}>
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                align="left"
            >
                Sets{" "}
            </Typography>
            <Grid container spacing={2}>
                {data.map((set) => (
                    <Grid item key={set.id}>
                        <Card className={classes.card}>
                            <CardContent>
                                <CardMedia className={classes.media}>
                                    <AspectRatio objectFit={"contain"} maxHeight={100} variant="plain">
                                        <img src={GetImageUrl(set.images.logo)} alt={"logo"}/>
                                    </AspectRatio>
                                </CardMedia>
                            </CardContent>
                            <CardContent>
                                <Typography color="textSecondary" variant="subtitle2">
                                    {set.series}
                                </Typography>
                                <Typography color="primary" variant="h5">
                                    <Link to={'/sets/' + set.id + "/cards"}>
                                        {set.name}
                                    </Link>
                                </Typography>
                                <Typography color="textSecondary" variant="subtitle2">
                                    Released: {set.releaseDate}
                                </Typography>
                                <Typography color="textSecondary" variant="subtitle2">
                                    {set.total} cards
                                </Typography>
                                <AspectRatio objectFit={"contain"} maxHeight={30} variant="plain">
                                    <img src={GetImageUrl(set.images.symbol)} alt={"symbol"}/>
                                </AspectRatio>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Sets;