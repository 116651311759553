import {useEffect, useState} from "react";

export function FetchData(path, initialState) {
    const url = process.env.REACT_APP_POKEDEX_API_BASE_URL + path
    const [data, setData] = useState(initialState);
    useEffect(() => {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setData(data);
            });
    }, [url]);
    return data;
}

export function GetImageUrl(path) {
    return process.env.REACT_APP_POKEDEX_IMAGE_BASE_URL + path
}