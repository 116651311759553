import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250,
        },
    },
};

export default function MultiSelect(props) {
    const [valueName, setValueName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setValueName(typeof value === 'string' ? value.split(',') : value);
        if (props.onChange !== undefined) {
            props.onChange(event);
        }
    };

    return (<div>
        <FormControl sx={{m: 1, width: 300}}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={valueName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                renderValue={(selected) => (<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                    {selected.map((value) => {
                        const selectedOption = props.data.find((option) => option.id === value);
                        return (<Chip
                            key={value}
                            label={selectedOption ? selectedOption.name : ''}
                        />);
                    })}
                </Box>)}
                MenuProps={MenuProps}
            >
                {props.data.map((value) => (<MenuItem key={value.id} value={value.id}>
                    {value.name}
                </MenuItem>))}
            </Select>
        </FormControl>
    </div>);
}
