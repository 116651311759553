import Typography from '@mui/material/Typography';
import Image from 'mui-image'
import {Container} from "@mui/material";

export default function Home() {
    return (
        <>
            <Image
                src={"/assets/images/pikachu_dark_wonder_cropped.jpeg"}
            />
            <Container>
                <Typography variant="h4" component="div">
                    Pokedex-Ultra
                </Typography>
                <Typography sx={{mb: 1.5}} color="text.secondary">
                    <p>
                        Looking for the ultimate tool to take your Pokemon TCG game to the next level? Look no further
                        than Pokedex-Ultra! Our state-of-the-art website allows you to search and browse all known
                        Pokemon, manage your collections, and create unbeatable card decks. Whether you're a seasoned
                        player or a passionate collector, Pokedex-Ultra has everything you need to stay ahead of the
                        game. So why wait? Sign up today and start building your ultimate Pokemon TCG collection!
                    </p>
                </Typography>
            </Container>
        </>
    )
}