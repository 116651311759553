import React, {useState} from 'react';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {FetchData} from "./Utils";
import MultiSelect from "./MultiSelect";

const SearchForm = () => {
    const [searchParams, setSearchParams] = useState({
        name: '',
        cardType: [],
        energyType: [],
        rarity: [],
        set: [],
    });

    const criteria = FetchData("/api/v1/cards/search/criteria", {
        cardTypes: [],
        energyTypes: [],
        rarities: [],
        sets: [],
    })

    const handleInputChange = (name, value) => {
        setSearchParams((prevParams) => ({...prevParams, [name]: value}));
    };

    const handleMultiSelectChange = (name, event) => {
        handleInputChange(name, event.target.value);
    };


    const handleSubmit = () => {
        // Convert searchParams object to a query string
        const queryString = Object.entries(searchParams)
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    // Join array values with a comma
                    return `${key}=${value.join(',')}`;
                }
                return `${key}=${value}`;
            })
            .join('&');

        // Perform the form submission logic or API call with the queryString
        console.log('Form submitted with query string:', queryString);
        // For an actual API call, you would construct the full URL, e.g., `api/search?${queryString}`
        // Construct the full URL
        const results = `/cards/search?${queryString}`;

        // Update the current URL
        window.location.href = results;
    };

    return (
        <Box maxWidth={300} mx="auto" mt={3}>
            <TextField
                label="Name"
                fullWidth
                margin="normal"
                value={searchParams.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
            />

            <FormControl fullWidth margin="normal">
                <MultiSelect
                    label={"Type"}
                    data={criteria.cardTypes}
                    onChange={(e) => handleMultiSelectChange('cardType', e)}
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <MultiSelect
                    label={"Energy"}
                    data={criteria.energyTypes}
                    onChange={(e) => handleMultiSelectChange('energyType', e)}
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <MultiSelect
                    label={"Rarity"}
                    data={criteria.rarities}
                    onChange={(e) => handleMultiSelectChange('rarity', e)}
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <MultiSelect
                    label={"Set"}
                    data={criteria.sets}
                    onChange={(e) => handleMultiSelectChange('set', e)}
                />
                {/*<InputLabel>Set</InputLabel>*/}
                {/*<Select*/}
                {/*    multiple*/}
                {/*    value={searchParams.set}*/}
                {/*    onChange={(e) => handleMultiSelectChange('set', e)}*/}
                {/*>*/}
                {/*    {setData.map(set => (*/}
                {/*        <MenuItem value={set.id}>{set.name}</MenuItem>*/}
                {/*    ))}*/}
                {/*</Select>*/}
            </FormControl>

            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Search
            </Button>
        </Box>
    );
};

export default SearchForm;
