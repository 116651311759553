import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        position: "relative", // added for relative positioning of closeButton
        backgroundColor: theme.palette.background.dark,
        // boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 3),

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    image: {
        maxWidth: "90vw",
        maxHeight: "90vh",
    },
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0,
        margin: theme.spacing(1),
    },
}));

function ImageModal(props) {
    const classes = useStyles();
    const {imageUrl, onClose} = props;

    return (
        <Modal
            className={classes.modal}
            open={true}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={true}>
                <div className={classes.paper}>
                    <img className={classes.image} src={imageUrl} alt="modal"/>
                    <button className={classes.closeButton} onClick={onClose}>
                        X
                    </button>
                </div>
            </Fade>
        </Modal>
    );
}


ImageModal.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ImageModal;
