import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Stack from "@mui/material/Stack";
import {Box} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: 10,
            width: '25ch',
        },
    },
    table: {
        marginLeft: 0,
        marginRight: 50,
        minWidth: 650,
    },
}));

export default function TrainerTable(props) {
    const classes = useStyles();

    // Define state for sorting and searching
    const [sortColumn, setSortColumn] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');

    // Define the column data for the table
    const columns = [
        {id: 'name', label: 'Name'},
        {id: 'trainerType', label: 'Trainer Type'},
        {id: 'cardCount', label: 'Card Count'},
    ];

    const [data, setData] = useState([]);


    useEffect(() => {
        fetch(process.env.REACT_APP_POKEDEX_API_BASE_URL + "/api/v1/trainers")
            .then((response) => response.json())
            .then((data) => {
                setData(data);
            });
    }, []);

    // Define a function for handling changes to the search query
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Define a function for sorting the data
    const sortedData = data.sort((a, b) => {
        const column = columns.find((column) => column.id === sortColumn);
        const aValue = column.id === 'cardCount' ? a.count : a[sortColumn];
        const bValue = column.id === 'cardCount' ? b.count : b[sortColumn];

        if (typeof aValue === 'string') {
            return sortDirection === 'asc'
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        } else {
            console.log("Sorting non-string column of type: " + typeof aValue)
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        }
    });

    // Define a function for filtering the data by search query
    const filteredData = sortedData.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Box sx={{marginLeft: '50px', marginRight: '50px'}}>
            <Stack direction={"row"}>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    align="inherit"
                >
                    {"Trainers"}
                </Typography>
                <TextField
                    id="outlined-search"
                    label="Search by name"
                    type="search"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </Stack>
            <TableContainer>
                <Table className={classes.table} aria-label="trainer table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    onClick={() => {
                                        if (column.id === sortColumn) {
                                            setSortDirection(
                                                sortDirection === 'asc' ? 'desc' : 'asc'
                                            );
                                        } else {
                                            setSortColumn(column.id);
                                            setSortDirection('asc');
                                        }
                                    }}
                                >
                                    {column.label}
                                    {sortColumn === column.id ? (
                                        <span>
                      {sortDirection === 'asc' ? ' ▲' : ' ▼'}
                    </span>
                                    ) : null}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    <Link to={"/trainer/" + row.name + "/cards"}>
                                        {row.name}
                                    </Link>
                                </TableCell>
                                <TableCell>{row.trainerType}</TableCell>
                                <TableCell>{row.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
