import React from 'react';
import ReactDOM from "react-dom/client";
import App from "./App";
import {ThemeProvider} from "@material-ui/core";
import {PokemoneTheme} from "./theme"

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={PokemoneTheme}>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);