import {AspectRatio} from "@mui/joy";
import React from "react";
import {Container} from "@mui/material";


export function ScaledImage(props) {
    return <Container>
        <AspectRatio maxHeight={props.height} objectFit={"contain"} variant="plain">
            <img src={props.src} alt={""}/>
        </AspectRatio>
    </Container>
}