import {BrowserRouter, Route, Routes,} from "react-router-dom";
import Home from "./Pages/Home";
import Card from "./Pages/Card";
import Sets from "./Pages/Sets";
import Pokemon from "./Pages/Pokemon";
import {GetArtistCards, GetDeckCards, GetPokemonCards, GetSetCards, GetTrainerCards, QueryCards} from "./Pages/Cards";
import DeckTable from "./Components/DeckTable";
import ArtistTable from "./Components/ArtistTable";
import TrainerTable from "./Components/TrainerTable";
import StickyNavbar from "./Components/StickyNavbar";
import React from "react";
import SearchForm from "./Components/SearchForm";
import ImportDeckForm from "./Components/ImportDeckForm";

function App() {
    return (
        <>
            <StickyNavbar/>
            <main>

                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/cards/:id" element={<Card/>}/>
                        <Route path="/cards/search" element={<QueryCards/>}/>
                        <Route path="/cards/search/form" element={<SearchForm/>}/>

                        <Route path="/decks" element={<DeckTable/>}/>
                        <Route path="/decks/import" element={<ImportDeckForm/>}/>
                        <Route path="/decks/:id" element={<GetDeckCards/>}/>
                        <Route path="/decks/:id/cards" element={<GetDeckCards/>}/>

                        <Route path="/artist/:name/cards" element={<GetArtistCards/>}/>
                        <Route path="/artists" element={<ArtistTable/>}/>

                        <Route path="/trainer/:name/cards" element={<GetTrainerCards/>}/>
                        <Route path="/trainers" element={<TrainerTable/>}/>

                        <Route path="/pokemon" element={<Pokemon/>}/>
                        <Route path="/pokemon/:name/cards" element={<GetPokemonCards/>}/>

                        <Route path="/sets" element={<Sets/>}/>
                        <Route path="/sets/:id/cards" element={<GetSetCards/>}/>
                    </Routes>
                </BrowserRouter>
            </main>
        </>
    );
}

export default App;