import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Badge from '@mui/material/Badge';
import {Box} from "@mui/material";
import {FetchData} from "../Components/Utils";

const useStyles = makeStyles((theme) => ({
    card: {
        width: 300,
        height: 350,
        boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.3)",
        backgroundColor: "#fafafa",
    },
    media: {
        height: 230,
        objectFit: "cover",
        alignItems: "center"
    },
}));

function Pokemon() {
    const classes = useStyles();
    const data = FetchData("/api/v1/pokemon", [])

    return (
        <Box sx={{marginLeft: '50px', marginRight: ''}}>
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                // align="center"
            >
                Pokémon{" "}
            </Typography>
            <Grid container spacing={2} style={{marginTop: "8px"}}>
                {data.map((pokemon) => (
                    <Grid item key={pokemon.id}>
                        <Card className={classes.card}>
                            <CardContent>

                                <CardMedia className={classes.media}>
                                    <Badge badgeContent={"Count: " + pokemon.cardCount}
                                           color="primary"
                                           overlap={"rectangular"}
                                           style={{fontSize: 24}}
                                    >
                                        <img
                                            src={"/assets/images/pokemon/" + pokemon.id.toString().padStart(3, '0') + ".png"}
                                            width={250} height={250}
                                        />
                                    </Badge>
                                </CardMedia>
                            </CardContent>
                            <CardContent>
                                <Typography color="textSecondary" variant="subtitle2">
                                    <Link to={"https://www.pokemon.com/us/pokedex/" + pokemon.name.toLowerCase()}>
                                        {pokemon.pkHashId}
                                    </Link>
                                </Typography>
                                <Typography color="primary" variant="h5">
                                    {pokemon.cardCount > 0
                                        ? <Link to={'/pokemon/' + pokemon.name + "/cards"}>{pokemon.name}</Link>
                                        : <>{pokemon.name}</>
                                    }
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Pokemon;
