import React, {useState} from "react";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ImageModal from "./ImageModal";
import {Container} from "@mui/material";
import Stack from "@mui/material/Stack";
import {ScaledImage} from "../Pages/ScaledImage";
import Grid from "@material-ui/core/Grid";
import {FetchData, GetImageUrl} from "./Utils";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

export var CardState = {
    images: {
        small: "",
        large: "",
    },
    set: {
        images: {}
    },
    evolvesFrom: "",
    evolvesTo: [],
    types: [],
    weaknesses: [],
    resistances: [],
    retreatCost: [],
    subtypes: [],
    rules: [],
    attacks: [],
}

export var SetCardsState = {
    set: {
        images: {
            logo: "",
            symbol: "",
        }
    },
    cards: [],

}

const useStyles = makeStyles(() => ({
    card: {
        width: 310,
        height: 420,

        // boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.3)",
        backgroundColor: "#fafafa",
    },
    card_details: {
        // boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.3)",
        backgroundColor: "#fafafa",
    },
    media: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        verticalAlign: "center",
        margin: 0
    },
    card_page_margins: {
        marginRight: 20,
        marginLeft: 20,
    }
}));

export function CardImage(props) {
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles();
    const handleClickOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <Card className={classes.card}>
            <CardContent>
                <CardMedia
                    className={classes.media}
                >
                    <img src={GetImageUrl(props.card.images.large)} width={280} alt={props.card.name}
                         onClick={handleClickOpen}/>
                    {isOpen && <ImageModal imageUrl={GetImageUrl(props.card.images.large)} onClose={handleClose}/>}
                </CardMedia>
            </CardContent>
        </Card>
    )
}

export function EnergyCard(props) {
    return <Container style={{marginLeft: 10, marginRight: 10}}>
        <CardImage card={props.card}/>
        <p/>
        <CardRarity rarity={getRarity(props.card)}/>
        <CardName card={props.card}/>
        <CardRules card={props.card}/>
        <CardArtist card={props.card}/>
    </Container>
}

function CardName(props) {
    let setTitle = props.card.set.series
    if (props.card.set.series !== props.card.set.name) {
        setTitle = props.card.set.series + " - " + props.card.set.name;
    }
    // always scroll to top whenever any card is loaded
    window.scrollTo({top: 0})
    return <p>
        <Typography color="textSecondary" variant="subtitle2">
            Set
        </Typography>
        <Typography color="textPrimary" variant="h6">
            {setTitle}
        </Typography>
        <Grid container spacing={0} justifyContent="flex-start">
            <Grid item xs={2}>
                <Link to={'/sets/' + props.card.set.id + '/cards/'}>
                    <ScaledImage src={GetImageUrl(props.card.set.images.logo)} height={100}/>
                </Link>
            </Grid>
        </Grid>

        <Typography color="" variant="h6">
            <p/>
            {props.card.supertype} - {getSubtypeString(props.card)}
        </Typography>
        <Typography color="primary" variant="h4">
            {props.card.name}
        </Typography>
        <p/>
    </p>
}

function CardRules(props) {
    if (props.card.rules != null) {
        return <div>
            <Typography color="textSecondary" variant="subtitle2">
                Rules
            </Typography>
            <Typography color="">
                {props.card.rules.map((rule) => (
                    <div>{rule}<p/></div>
                ))}
            </Typography>
            <p/>
        </div>
    }
}

function CardRarity(props) {
    return <div>
        <Typography color="textSecondary" variant="subtitle2">
            Rarity
        </Typography>
        <Typography color="" variant={"h6"}>
            {props.rarity}
        </Typography>
        <p/>
    </div>
}

function CardArtist(props) {
    return <p>
        <Typography color="textSecondary" variant="subtitle2">
            Artist
        </Typography>
        <Typography color="primary" variant={"h5"}>
            <Link to={'/artist/' + props.card.artist + '/cards/'}>
                {props.card.artist}
            </Link>
        </Typography>
        <p/>
    </p>
}

export function TrainerCard(props) {
    return <div style={{marginLeft: 20, marginRight: 20}}>
        <CardImage card={props.card}/>
        <p/>
        <CardRarity rarity={getRarity(props.card)}/>
        <CardName card={props.card}/>
        <CardRules card={props.card}/>
        <Abilities values={props.card.abilities}/>
        <CardArtist card={props.card}/>
    </div>
}


export function PokemonCard(props) {
    return <div style={{marginLeft: 20, marginRight: 20}}>
        <CardImage card={props.card}/>
        <p/>
        <CardRarity rarity={getRarity(props.card)}/>
        <CardName card={props.card}/>
        <Evolutions card={props.card}/>
        <Flavor card={props.card}/>
        <HP card={props.card}/>
        <CardRules card={props.card}/>
        <Abilities values={props.card.abilities}/>
        <Attacks values={props.card.attacks}/>
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={6}
        >
            <Stack item>
                <EnergyAdvantage title={"Weakness"} values={props.card.weaknesses}/>
            </Stack>
            <Stack item>
                <EnergyAdvantage title={"Resistance"} values={props.card.resistances}/>
            </Stack>
            <Stack item>
                <p>
                    <Typography color="textSecondary" variant="subtitle2">
                        {"Retreat Cost"}
                    </Typography>
                    <EnergySet values={props.card.retreatCost}/>
                </p>
            </Stack>
        </Stack>
        <CardArtist card={props.card}/>
    </div>
}

function Evolutions(props) {
    let hasFrom = props.card.evolvesFrom !== null && props.card.evolvesFrom !== "";
    let hasTo = props.card.evolvesTo !== null && props.card.evolvesTo.length > 0;
    console.log("Card:")
    console.log(props.card)
    return <>
        <Typography color="textPrimary" variant="h6">
            {"Evolutions"}
        </Typography>

        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
        > {hasFrom &&
            <>
                <Stack item>
                    <PokemonEvolutionAvatar cardName={props.card.evolvesFrom}/>
                    <Typography variant={"h6"}>
                        <Link to={'/pokemon/' + props.card.evolvesFrom + "/cards"}>{props.card.evolvesFrom}</Link>
                    </Typography>
                </Stack>
                <Stack item>
                    <ArrowCircleRightOutlinedIcon sx={{fontSize: 40, marginTop: 5}} color="primary"/>
                </Stack>
            </>
        }
            {<Stack item>
                <PokemonEvolutionAvatar cardName={props.card.name}/>
                <Typography variant={"h6"}>
                    <Link to={'/pokemon/' + props.card.name + "/cards"}>{props.card.name}</Link>
                </Typography>
            </Stack>
            }
            {hasTo &&
                <>
                    <Stack item>
                        <ArrowCircleRightOutlinedIcon sx={{fontSize: 40, marginTop: 5}} color="primary"/>
                    </Stack>
                    <Stack item>
                        {props.card.evolvesTo.map(function (cardName) {
                            return <>
                                <PokemonEvolutionAvatar cardName={cardName}/>
                                <Typography variant={"h6"}>
                                    <Link to={'/pokemon/' + cardName + "/cards"}>{cardName}</Link>
                                </Typography>
                            </>
                        })}
                    </Stack>
                </>
            }
        </Stack>
        <p/>
    </>
}

function PokemonEvolutionAvatar(props) {
    // console.log("Card Name: " + props.cardName);
    let data = FetchData("/api/v1/pokemon/cardname/" + props.cardName, {"name": "", "imageId": ""});
    console.log(data)
    if (data !== null) {
        return <img
            width={110} heght={110}
            src={"/assets/images/pokemon/" + data.imageId}
            alt={""}
        />
    }
}

function HP(props) {
    return <p>
        <Typography color="textSecondary" variant="subtitle2">
            HP
        </Typography>
        <Typography variant={"h4"}>
            {props.card.hp} <EnergySet values={props.card.types}/>
        </Typography>
    </p>
}

function Flavor(props) {
    if (props.card.flavorText !== null && props.card.flavorText !== "") {
        return <p>
            <Typography color="textSecondary" variant="subtitle2">
                About
            </Typography>
            <Typography>
                {props.card.flavorText}
                <p/>
            </Typography>
        </p>
    }
}

export function EnergySet(props) {
    if (props.values != null) {
        return <span>
                {props.values.map((energy) => (
                    <img src={"/assets/images/energy/" + energy + ".png"} alt={energy} width={25} height={25}/>
                ))}
            </span>
    }
}

export function EnergyAdvantage(props) {
    if (props.values == null) {
        return <p><Typography color="textSecondary" variant="subtitle2">
            {props.title}
        </Typography>
            <Typography>
                {" "}
            </Typography>
        </p>
    }
    return <p>
        <Typography color="textSecondary" variant="subtitle2">
            {props.title}
        </Typography>
        <Typography variant={"h5"}>
            {props.values.map((advantage) => (
                <span>
                    <img src={"/assets/images/energy/" + advantage.type + ".png"} alt={advantage.type} width={25}
                         height={25}/>
                    {" "}{advantage.value}
                    </span>
            ))}
        </Typography>
    </p>
}

export function Attacks(props) {
    if (props.values != null) {
        return <p>
            <Typography>
                {props.values.map((attack) => (
                        <p>
                            <Typography color="textSecondary" variant="subtitle2">
                                Attack
                            </Typography>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Stack item>
                                    <EnergySet values={attack.cost}/>
                                </Stack>
                                <Stack item>
                                    <Typography variant={"h6"}>{attack.name} {attack.damage}</Typography>
                                </Stack>
                            </Stack>
                            <p>{attack.text}</p>
                        </p>
                    )
                )}
            </Typography>
        </p>
    }
}

export function Abilities(props) {
    if (props.values != null) {
        return <span>
            {props.values.map((ability) => (
                <>
                    <Typography color="">
                        <Typography color="textSecondary" variant="subtitle2">{ability.type}</Typography>
                        <Typography color="error" variant={"h6"}>{ability.name}</Typography>
                    </Typography>
                    <Typography color="textSecondary">
                        {ability.text}
                    </Typography>
                    <p/>
                </>
            ))}
            <p/>
        </span>
    }
}

function getRarity(card) {
    if (card.rarity === "") {
        return "Common"
    } else {
        return card.rarity
    }
}

function getSubtypeString(card) {
    if (card.subtypes === null || card.subtypes === []) {
        return ""
    } else {
        return card.subtypes.join(" | ")
    }
}