import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Link, useLocation, useParams} from "react-router-dom";
import {SetCardsState} from "../Components/PokemonCard";
import {Box} from "@mui/material";
import {ScaledImage} from "./ScaledImage";
import {FetchData, GetImageUrl} from "../Components/Utils";

const useStyles = makeStyles(() => ({
    card: {
        width: 300,
        height: 414,
        boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.3)",
        backgroundColor: "#fafafa"
    },
    media: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    setHeader: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

export function GetSetCards() {
    const {id} = useParams()
    const classes = useStyles()
    let path = "/api/v1/sets/" + id + "/setcards"
    const data = FetchData(path, SetCardsState)
    let header = <>
        <Grid container spacing={0} justifyContent="flex-start">
            <Grid item xs={4}>
                <ScaledImage src={GetImageUrl(data.set.images.logo)} height={100}/>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <Typography color="textSecondary" variant="subtitle2">
                        {data.set.series}
                    </Typography>
                    <Typography color="primary" variant="h5">
                        {data.set.name}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                        Released: {data.set.releaseDate}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                        {data.set.total} cards
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        {/*<ScaledImage src={data.set.images.logo} height={100}/>*/}
        {/*<ScaledImage src={data.set.images.symbol} height={30}/>*/}
    </>
    return Cards(data.cards, header)
}

export function GetDeckCards() {
    const {id} = useParams()
    let path = "/api/v1/decks/" + id + "/cards"
    const data = FetchData(path, {name: "", cards: []})
    console.log("Fetching deck data")
    let header = <>
        <Typography color="textSecondary">
            {"Deck"}
        </Typography>
        <Typography color="textPrimary" variant="h4">
            {data.name}
        </Typography>
    </>
    return Cards(data.cards, header)
}

export function GetArtistCards() {
    const {name} = useParams()
    let path = "/api/v1/artist/" + name
    const cards = FetchData(path, [])

    let header = <>
        <Typography color="textSecondary">
            {"Artist"}
        </Typography>
        <Typography color="textPrimary" variant="h4">
            {name}
        </Typography>
    </>
    return Cards(cards, header)
}

export function GetTrainerCards() {
    const {name} = useParams()
    let path = "/api/v1/trainer/" + name
    const cards = FetchData(path, [])

    let header = <>
        <Typography color="textSecondary">
            {"Trainer"}
        </Typography>
        <Typography color="textPrimary" variant="h4">
            {name}
        </Typography>
    </>
    return Cards(cards, header)
}

export function GetPokemonCards() {
    const {name} = useParams()
    let path = "/api/v1/pokemon/" + name + "/cards"
    const cards = FetchData(path, [])
    let header = <>
        <Typography color="textSecondary">
            {"Pokémon"}
        </Typography>
        <Typography color="textPrimary" gutterBottom variant="h4" align="left">
            {name}
        </Typography>
    </>
    return Cards(cards, header)
}

export function QueryCards() {
    const search = useLocation().search
    let path = "/api/v1/cards/search" + search
    console.log(path)

    const cards = FetchData(path, [])

    let header = <>
        <Typography color="textSecondary">
            {"Search"}
        </Typography>
        {cards != null &&
            <Typography color="textPrimary" gutterBottom variant="h6" align="left">
                Found {cards.length} cards matching query.
            </Typography>
        }
    </>
    return Cards(cards, header)
}

export function Cards(cards, header) {
    const classes = useStyles();
    if (cards == null) {
        return (<Box sx={{marginLeft: '50px', marginRight: ''}}>
            {header}
            <Typography color="textPrimary" gutterBottom variant="h4" align="left">
                No matching cards found.
            </Typography>
        </Box>);
    }
    return (
        <Box sx={{marginLeft: '50px', marginRight: ''}}>
            {header}
            <Box sx={{width: '100%'}}>
                <Grid container spacing={3}>
                    {cards.map((card) => (
                        <Grid item key={card.id}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <CardMedia className={classes.media}>
                                        <Link to={"/cards/" + card.canonicalId}>
                                            <img src={GetImageUrl(card.images.large)} width={280} alt={card.name}/>
                                        </Link>
                                    </CardMedia>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

